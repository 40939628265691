import { Directive, inject } from '@angular/core';
import { NgxSvgSpriteFragment } from 'ngxtension/svg-sprite';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'svg[flag]',
  standalone: true,
  hostDirectives: [{ directive: NgxSvgSpriteFragment, inputs: ['fragment:flag'] }],
})
export class FlagDirective {
  constructor() {
    inject(NgxSvgSpriteFragment).sprite = 'flags';
  }
}
